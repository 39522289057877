.tableOuterContainer {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
}
.tableContainer {
  flex: 1;
  padding: 20px;
}

.tableContainer table {
  width: 100%;
  border-collapse: collapse;
}

.tableContainer table th,
.tableContainer table td {
  padding: 8px;
}

.tableContainer table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.tableContainer table tr:hover {
  background-color: #ddd;
}

.tableContainer table th {
  padding-top: 12px;
  padding-bottom: 12px;
  background: #c5cee2;
  color: #152c70;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-align: left;
}

.orgNameCell {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #101840;
}

.tableContainer table td {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #363939;
  vertical-align: middle;
  background-color: #FFF;
}

.walletAddressCell {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  letter-spacing: 0.01em;
  text-align: left;
}

.dropdownToggleButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #565D6D;
}

.actionCell {
  text-align: center;
  width: 50px;
}

.actionMenuContainer {
  text-align: center;
}

.button {
  background: none;
  border: none;
  font-family: Poppins;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.dropdownToggleButton::after {
  display: none;
}

.status {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
  line-height: 17.71px;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: capitalize;
  padding: 6px;
  border-radius: 8px;
}

.status.active {
  background-color: #59F19F;
}

.status.pending {
  background-color: #f5f6f8;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.pageButton,
.arrowButton {
  background-color: #afafaf;
  color: white;
  border: none;
  padding: 8px 12px;
  margin: 2px 2px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
}

.pageButton.active {
  background-color: #555555;
  font-weight: bold;
}

.arrowButton:disabled,
.pageButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pageButton:hover:not(.active),
.arrowButton:hover:not(:disabled) {
  background-color: #555555;
}

.inputWrapper {
  padding: 0 20px; 
}

.searchInput {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid transparent; 
  border-radius: 4px;
  box-shadow: none; 
  outline: none;
}