/* App.css */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}
html, * {
  box-sizing: border-box;
}

.App {
  display: flex;
  height: 100vh;
  position: relative;
  flex-direction: column;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
  width: 100%;
}

.login-form {
  background-color: white;
  padding: 40px;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form h2 {
  color: black;
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.login-form-text {
  color: #696969;
  font-size:x-small;
}

.form-group input {
  padding: 10px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 15px;
  
}

.terms {
  color: #696969;
  font-size: smaller;
  display: flex;
  gap: 10px;
  margin-bottom: 50px;
  margin-top: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.login-form button {
  background-color: grey;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 15px;
  cursor: pointer;
  width: 300px;
}

.login-form button:hover {
  background-color: #555;
}

.main-content {
  flex-grow: 1;
  padding: 0;
  background-color: #f5f6f8;
  margin-left: 200px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 50%;
  max-width: 600px;
  box-sizing: border-box;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  line-height: 1;
}

.main-page-content {
  padding: 20px;
}

.loan-table,
.note-table,
.history-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1em;
  font-family: 'Arial', sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.loan-table thead tr,
.note-table thead tr,
.history-table thead tr {
  background-color: #C5CEE2;
  color: #152c70;
  text-align: left;
}

.loan-table th,
.note-table th,
.history-table th,
.loan-table td,
.note-table td,
.history-table td {
  padding: 12px 15px;
  vertical-align: top;
}

.loan-table tbody tr,
.note-table tbody tr,
.history-table tbody tr {
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
}

.loan-table tbody tr:nth-of-type(even),
.note-table tbody tr:nth-of-type(even),
.history-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.loan-table tbody tr:hover,
.note-table tbody tr:hover,
.history-table tbody tr:hover {
  background-color: #c4c4c4;
}


.selected-loan-container .loan-box {
  background-color: #f0f8ff;
  border: 1px solid #1a73e8;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.selected-loan-container .loan-box strong {
  font-size: 1.2em;
  color: #1a73e8;
}

.selected-loan-container .loan-box ul {
  list-style: none;
  padding: 0;
}

.selected-loan-container .loan-box ul li {
  margin-bottom: 8px;
  font-size: 0.9em;
  color: #555555;
}

.selected-loan-container .loan-box ul li strong {
  font-weight: 600;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.page-button,
.arrow-button {
  background-color: #afafaf;
  color: white;
  border: none;
  padding: 8px 12px;
  margin: 2px 2px;
  border-radius: 55px;
  cursor: pointer;
  font-size: 16px;
}

.page-button.active {
  background-color: #555555;
  font-weight: bold;
}

.arrow-button:disabled,
.page-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.page-button:hover:not(.active),
.arrow-button:hover:not(:disabled) {
  background-color: #555555;
}