/* ButtonGroup.css */
.button-group {
    display: flex;
    gap: 10px; 
    margin-top: 20px; 
  }
  
  .button-group .btn {
    padding: 10px 20px; 
    background-color: #152c70;
    color: white; 
    border: none; 
    border-radius: 5px; 
    cursor: pointer; 
  }
  
  .button-group .btn:hover {
    background-color: #04012c; 
  }
  