.note-details-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 20px auto;
}

.note-section, .report-section, .holder-section {
  flex: 1;
  margin: 0 10px;
}

.note-section h2, .report-section h3, .holder-section h3 {
  margin-bottom: 15px;
  color: #333;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
}

p {
  margin: 8px 0;
  font-size: 15px;
  color: #555;
}

p strong {
  color: #333;
}

.note-details-container p + p {
  margin-top: 10px;
}

.note-section, .report-section, .holder-section {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

@media (max-width: 768px) {
  .note-details-container {
    flex-direction: column;
  }

  .note-section, .report-section, .holder-section {
    margin-bottom: 20px;
  }
}
