.otp-modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.otp-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 35px;
  padding-top: 60px;
  padding-left: 60px;
  padding-right: 60px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-title {
  color: black;
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
}

.modal-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.modal-input {
  padding: 10px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin-bottom: 20px;
  font-size: 16px;
}

.modal-button {
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  width: 300px;
  margin-bottom: 10px;
}

.modal-submit {
  background-color: grey;
  color: white;
}

.modal-submit:hover {
  background-color: #555;
}

.modal-cancel {
  background-color: white;
  color: #f44336;
  border: 2px solid #f44336; 
  border-radius: 15px; 
  padding: 10px 20px;
}

.modal-cancel:hover {
  background-color: #d32f2f;
  color: white;
}

.image-logo {
  margin-bottom: 20px;
}